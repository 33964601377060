/**
 * @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. 
*/

import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Button, CardGroup, Col, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as ApiService from "../../Services/APIServices";
import { ShowCardList } from "../../Services/APIServices";
import {
  BackIcon,
  CrossIcon,
  DeletecircleIcon,
  MasterCardIcon,
  VisaIcon,
} from "../../SvgIcons/allIcons";

import { Selected } from "../../App";
import { LENDER, MASTERCARD, RENTER, VISA } from "../../globals/constants";
import Loader from "../Loader/Loader";
import AddCardModal from "./AddCardModal";
import AddBankModal from "./AddBankModal";
import useRole from "../../hooks/useRole";

export default function Payments() {
  const userRole = useRole();
  const { setIsSelected } = useContext(Selected);
  const [loading, setLoading] = useState(false);
  const [showPaymentDelete, setshowPaymentDelete] = useState(false);
  const handleClosePayment = () => setshowPaymentDelete(false);
  const [showPaymentDelete1, setshowPaymentDelete1] = useState(false);
  const handleClosePayment1 = () => setshowPaymentDelete1(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };

  const [cardDetails, setCardDetails] = useState([]);
  const [details, setDetails] = useState({
    id: "",
    name: "",
    accNo: "",
    expiry_date: "",
    cvv: "",
  });
  const [rid, setRid] = useState("");

  useEffect(() => {
    cardList();
    if (userRole == LENDER) {
      bankList();
    }
  }, []);

  /**
   * Get list fo Added STRIP Cards
   */

  const cardList = () => {
    setLoading(true);
    ShowCardList().then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setCardDetails(res?.data?.data);
      }
    });
  };

  const handleShowPayment = (data) => {
    setDetails({
      name: data.card_holder_name,
      card_number: data?.ac_no,
      id: data?.id,
      expiry_date: `${data?.exp_month}/${data?.exp_year}`,
      cvv: data?.cvv,
    });
    setRid(data?.id);
    setshowPaymentDelete(true);
  };

  const handleRemoveAllPopUps = () => {
    setshowPaymentDelete(false);
    setshowPaymentDelete1(false);
    setShowBankDelete(false);
    setShowBankDelete1(false);
  };

  const handleShowPayment1 = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("card_id", rid);
    setLoading(true);
    ApiService.DeleteCard(formData).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setshowPaymentDelete1(true);
        setshowPaymentDelete(false);
        cardList();
      }
    });
  };

  const handleCardIcon = (type) => {
    if (type == VISA) {
      return <MasterCardIcon />;
    } else if (type == MASTERCARD) {
      return <VisaIcon />;
    } else {
      return <></>;
    }
  };

  /********************************Bank Functionality*****************************/
  const [showAddBank, setShowAddBank] = useState(false);
  const [banks, setBanks] = useState([]);
  const [bankDetails, setBankDetails] = useState("");

  const [showBankDelete, setShowBankDelete] = useState(false);
  const handleCloseBank = () => setShowBankDelete(false);
  const [showBankDelete1, setShowBankDelete1] = useState(false);
  const handleCloseBank1 = () => setShowBankDelete1(false);

  const bankList = () => {
    ApiService.getAllBank().then((resp) => {
      if (resp?.status === 200) {
        setBanks(Array.isArray(resp?.data?.data) ? resp?.data?.data : []);
      }
    });
  };

  const handleShowBank1 = () => {
    setLoading(true);
    ApiService.deleteBank(bankDetails?.id).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setShowBankDelete1(true);
        setShowBankDelete(false);
        bankList();
      }
    });
  };

  const handleShowBankData = (data) => {
    setBankDetails(data);
    setShowBankDelete(true);
  };

  return (
    <>
      <div className="px-4 p-lg-0">
        <div className="d-flex justify-content-between me-5">
          <p className="head16  d-flex align-items-center gap-3">
            <Link
              className="d-block d-lg-none d-sm-none"
              onClick={(e) => {
                e.preventDefault();
                setIsSelected(false);
              }}
            >
              <BackIcon />
            </Link>
            Payments
          </p>
        </div>
      </div>
      {userRole == RENTER ? (
        <>
          <Row className="px-4 px-lg-0">
            <p className="font12 font700 mb-0 mb-lg-3 ms-2 mb-2">Cards</p>
            {cardDetails &&
              cardDetails?.length > 0 &&
              cardDetails?.map((data, index) => {
                return (
                  <Col lg={4} md={6} className="mb-3" key={index}>
                    <CardGroup className="addresscard payment-view-card payment-gredientcard heightauto">
                      <div className="d-flex justify-content-between w-100">
                        <div className="w-100">
                          <p className="mt-lg-5 text-white">
                            {handleCardIcon(data?.card_type)}
                          </p>
                          <p className="mb-2 text-white">
                            {data?.card_holder_name}
                          </p>
                          <p className="mb-2 font400 text-white">
                            XXXX-XXXX-XXXX-
                            {data?.ac_no}
                          </p>
                          <p className="mb-0 font400 text-white">
                            Exp. {moment(data?.exp_month, "MM").format("MM")}/
                            {moment(data?.exp_year, "YYYY").format("YY")}
                          </p>
                          {data?.default && (
                            <p className="mb-0 font400 text-white text-end" style={{ marginTop: "-15px" }}>Default Card</p>
                          )}
                        </div>
                        <div className="d-flex">
                          <Link onClick={() => handleShowPayment(data)}>
                            <DeletecircleIcon />
                          </Link>
                        </div>
                      </div>
                    </CardGroup>
                  </Col>
                );
              })}

            <Col lg={4} md={6} className="mb-3">
              <Link onClick={handleShow} className="d-none d-md-block h-100">
                <CardGroup className="addresscard payment-view-card  add-payment addadress d-flex align-items-center justify-content-center">
                  <p className="mb-0 font700">Add new card</p>
                </CardGroup>
              </Link>

              {userRole == RENTER ? (
                <Link to="/renter/add-new-card" className="d-block d-md-none">
                  <CardGroup className="addresscard add-payment addadress d-flex align-items-center justify-content-center">
                    <p className="mb-0 font700">Add new card</p>
                  </CardGroup>
                </Link>
              ) : (
                <Link to="/lender/add-new-card" className="d-block d-md-none">
                  <CardGroup className="addresscard add-payment addadress d-flex align-items-center justify-content-center">
                    <p className="mb-0 font700">Add new card</p>
                  </CardGroup>
                </Link>
              )}
            </Col>
          </Row>
          <br />
        </>
      ) : (
        <>
          {" "}
          <p className="font12 font700 px-4 p-lg-0">Bank Accounts</p>
          <Row className="px-4 p-lg-0">
            {banks?.map((list, index) => {
              return (
                <Col key={index} lg={4} md={6} className="mb-3">
                  <CardGroup className="addresscard">
                    <div className="d-flex justify-content-between w-100">
                      <div>
                        <p className="mb-2">{list?.account_holder_name}</p>
                        <p className="mb-0 font400">
                          Ending with {list?.last_four}
                        </p>
                        <p className="mb-0 font400">{list?.bank_name}</p>
                      </div>
                      <div className="d-flex">
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            handleShowBankData(list);
                          }}
                        >
                          <DeletecircleIcon />
                        </Link>
                      </div>
                    </div>
                  </CardGroup>
                </Col>
              );
            })}

            <Col lg={4} md={6} className="mb-3">
              <CardGroup
                className="addresscard addadress d-flex align-items-center justify-content-center"
                onClick={() => {
                  setShowAddBank(true);                
                }}
              >
                <p className="mb-0 font700">Add new bank</p>
              </CardGroup>
            </Col>
          </Row>
          {/* Adding the Card details for the Lender */}
          <Row className="px-4 px-lg-0">
            <p className="font12 font700 mb-0 mb-lg-3 ms-2 mb-2">Cards</p>
            {cardDetails &&
              cardDetails?.length > 0 &&
              cardDetails?.map((data, index) => {
                return (
                  <Col lg={4} md={6} className="mb-3" key={index}>
                    <CardGroup className="addresscard payment-view-card payment-gredientcard heightauto">
                      <div className="d-flex justify-content-between w-100">
                        <div className="w-100">
                          <p className="mt-lg-5 text-white">
                            {handleCardIcon(data?.card_type)}
                          </p>
                          <p className="mb-2 text-white">
                            {data?.card_holder_name}
                          </p>
                          <p className="mb-2 font400 text-white">
                            XXXX-XXXX-XXXX-
                            {data?.ac_no}
                          </p>
                          <p className="mb-0 font400 text-white">
                            Exp. {moment(data?.exp_month, "MM").format("MM")}/
                            {moment(data?.exp_year, "YYYY").format("YY")}
                          </p>
                          {data?.default && (
                            <p
                              className="mb-0 font400 text-white text-end"
                              style={{ marginTop: "-15px" }}
                            >
                              Default Card
                            </p>
                          )}
                        </div>
                        <div className="d-flex">
                          <Link onClick={() => handleShowPayment(data)}>
                            <DeletecircleIcon />
                          </Link>
                        </div>
                      </div>
                    </CardGroup>
                  </Col>
                );
              })}

            <Col lg={4} md={6} className="mb-3">
              <Link onClick={handleShow} className="d-none d-md-block h-100">
                <CardGroup className="addresscard payment-view-card  add-payment addadress d-flex align-items-center justify-content-center">
                  <p className="mb-0 font700">Add new card</p>
                </CardGroup>
              </Link>

              {userRole == RENTER ? (
                <Link to="/renter/add-new-card" className="d-block d-md-none">
                  <CardGroup className="addresscard add-payment addadress d-flex align-items-center justify-content-center">
                    <p className="mb-0 font700">Add new card</p>
                  </CardGroup>
                </Link>
              ) : (
                <Link to="/lender/add-new-card" className="d-block d-md-none">
                  <CardGroup className="addresscard add-payment addadress d-flex align-items-center justify-content-center">
                    <p className="mb-0 font700">Add new card</p>
                  </CardGroup>
                </Link>
              )}
            </Col>
          </Row>
        </>
      )}

      {/*********************Card Delete Details Show Modal ***********************/}
      <Modal
        show={showPaymentDelete}
        onHide={handleClosePayment}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="border0"
      >
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="text-start">Delete card </h4>
            <a onClick={handleClosePayment}>
              <CrossIcon />
            </a>
          </div>
          <p className="font700">Do you want remove following Card?</p>

          <Row>
            <Col lg={12}>
              <CardGroup className="addresscard mb-4 ">
                <div className="d-flex flex-column w-100">
                  <p className="mb-0 font400">
                    XXXX-XXXX-XXXX-
                    {details?.card_number}
                  </p>
                  <p className="mb-0 font700">{details?.name} </p>
                  <p className="mb-0 font400 ">
                    Exp:{" "}
                    {moment(details?.expiry_date, "MM/YYYY").format("MM/YYYY")}
                  </p>
                </div>
              </CardGroup>
            </Col>
          </Row>
          <div className="text-center mx-auto">
            <Button
              className="btn btn-danger mb-0 mt-2 w-100"
              onClick={() => handleShowPayment1()}
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/*********************Card Confirm Delete Modal ***********************/}

      <Modal
        show={showPaymentDelete1}
        onHide={handleClosePayment1}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="border0"
      >
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="text-start">Card deleted!</h4>
            <a onClick={handleClosePayment1}>
              <CrossIcon />
            </a>
          </div>
          <p className="font700">Following card has been deleted!</p>

          <Row>
            <Col lg={12}>
              <CardGroup className="addresscard mb-4 ">
                <div className="d-flex flex-column w-100">
                  <p className="mb-0 font400">
                    XXXX-XXXX-XXXX-
                    {details?.card_number}
                  </p>
                  <p className="mb-0 font700"> {details?.name}</p>
                  <p className="mb-0 font400 ">
                    Exp:{" "}
                    {moment(details?.expiry_date, "MM/YYYY").format("MM/YYYY")}
                  </p>
                </div>
              </CardGroup>
            </Col>
          </Row>
          <Button
            className="btn btn-theme mb-0 mt-2 w-100"
            onClick={handleRemoveAllPopUps}
          >
            Dismiss
          </Button>
        </Modal.Body>
      </Modal>

      {/*********************bank Delete Details Show Modal ***********************/}
      <Modal
        show={showBankDelete}
        onHide={handleCloseBank}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="border0"
      >
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="text-start">Delete bank account </h4>
            <a onClick={handleCloseBank}>
              <CrossIcon />
            </a>
          </div>
          <p className="font700">Do you want remove following bank account?</p>

          <Row>
            <Col lg={12}>
              <CardGroup className="addresscard mb-4 ">
                <div className="d-flex flex-column w-100">
                  <p className="mb-2">{bankDetails?.account_holder_name}</p>
                  <p className="mb-0 font400">
                    Ending with
                    {bankDetails?.last_four}
                  </p>
                  <p className="mb-0 font700">{bankDetails?.name} </p>
                  <p className="mb-0 font400 ">{bankDetails?.bank_name}</p>
                </div>
              </CardGroup>
            </Col>
          </Row>
          <div className="text-center mx-auto">
            <Button
              className="btn btn-danger mb-0 mt-2 w-100"
              onClick={() => handleShowBank1()}
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/*********************Bank Confirm Delete Modal ***********************/}

      <Modal
        show={showBankDelete1}
        onHide={handleCloseBank1}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="border0"
      >
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="text-start">Bank account deleted!</h4>
            <a onClick={handleCloseBank1}>
              <CrossIcon />
            </a>
          </div>
          <p className="font700">Following bank account has been deleted!</p>

          <Row>
            <Col lg={12}>
              <CardGroup className="addresscard mb-4 ">
                <div className="d-flex flex-column w-100">
                  <p className="mb-2">{bankDetails?.account_holder_name}</p>
                  <p className="mb-0 font400">
                    Ending with
                    {bankDetails?.last_four}
                  </p>
                  <p className="mb-0 font700">{bankDetails?.name} </p>
                  <p className="mb-0 font400 ">{bankDetails?.bank_name}</p>
                </div>
              </CardGroup>
            </Col>
          </Row>
          <Button
            className="btn btn-theme mb-0 mt-2 w-100"
            onClick={handleRemoveAllPopUps}
          >
            Dismiss
          </Button>
        </Modal.Body>
      </Modal>

      {/*********************Add Card Modal ***********************/}
      <AddCardModal
        setLoading={setLoading}
        cardList={cardList}
        handleClose={handleClose}
        show={show}
        setShow={setShow}
      />

      {/*********************Add Bank Modal ***********************/}
      <AddBankModal
        showAddBank={showAddBank}
        setShowAddBank={setShowAddBank}
        setLoading={setLoading}
        bankList={bankList}
      />

      {loading ? <Loader /> : null}
    </>
  );
}
