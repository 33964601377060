/*
* @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. 
*/

import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BackIcon,
  CalendarIcon,
  CrossIcon,
  GetReceiptIcon,
  SupportIcon,
  TrackingIcon,
  WritereviewIcon,
} from "../../SvgIcons/allIcons";

import { useFormik } from "formik";
import moment from "moment";
import { Button, Col, Modal, Row } from "react-bootstrap";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactStars from "react-stars";
import * as Yup from "yup";
import Footer from "../../Commons/Footer";
import Header from "../../Commons/Headers/Header";
import LenderHeader from "../../Commons/Headers/LenderHeader";
import RenterHeader from "../../Commons/Headers/RenterHeader";
import Loader from "../../Commons/Loader/Loader";
import {
  getPriceEstimation,
  getProductDetailsAfterCheckout,
  getProductImageDetails,
  productImages,
  productLabel,
  actionDeliveryRequest,
} from "../../Services/APIServices";
import { imageBase } from "../../globals/Config";
import {
  DEFAULT_CURRENCY,
  LENDER,
  RENTER,
  SELF,
  HOME,
  STORE,
  SELF_DELIVERY_REQUEST_PENDING,
  SELF_DELIVERY_REQUEST_ACCEPTED,
  SELF_DELIVERY_REQUEST_REJECTED,
} from "../../globals/constants";
import useRole from "../../hooks/useRole";
import DefaultProfileImg from "../../Commons/DefaultProfileImg";
import { splitFullName } from "../../globals/HelperCommon";
import MessageModal from "./../CommonModals/MessageModal";
import showNotification from "../../Services/NotificationService";
const ReservationDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const role = useRole();
  const [product, setProduct] = useState("");
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [priceDetails, setPriceDetails] = useState("");
  const userDetail = useSelector((state) => state?.Login?.userDetails);
  const id = new URLSearchParams(location?.search).get("id"); // Order item ID
  const pid = new URLSearchParams(location?.search).get("pid"); //product id

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseTwo = () => setShow(false);
  const [showOne, setShowOne] = useState(false);
  const handleCloseOne = () => setShowOne(false);
  const handleShowOne = () => setShowOne(true);
  const [sliderImg, setSliderImg] = useState([]);
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);
  const [pendingOrderId, setPendingOrderId] = useState(null);
  const [respondToRenter, setRespondToRenter] = useState(false); //True / False

  const openMessageModal = (action, orderId) => {
    setPendingAction(action);
    setPendingOrderId(orderId);
    setShowMessageModal(true);
  };

  useEffect(() => {
    ProductDetails();
    ProductImageDetails();
  }, []);

  const [labelapiResponse, setlabelapiResponse] = useState();
  const validationSchema = Yup.object().shape({
    front_image: Yup.mixed()
      .test("required", "Please upload front image", (value) => {
        return value != null;
      })
      .test("type", "Please select png, jpeg, jpg format", function (value) {
        return (
          value &&
          (value.type === "image/jpg" ||
            value.type === "image/jpeg" ||
            value.type === "image/png")
        );
      }),

    back_image: Yup.mixed()
      .test("required", "Please upload back image", (value) => {
        return value != null;
      })
      .test("type", "Please select png, jpeg, jpg format", function (value) {
        return (
          value &&
          (value.type === "image/jpg" ||
            value.type === "image/jpeg" ||
            value.type === "image/png")
        );
      }),

    left_image: Yup.mixed()
      .test("required", "Please upload left image", (value) => {
        return value != null;
      })
      .test("type", "Please select png, jpeg, jpg format", function (value) {
        return (
          value &&
          (value.type === "image/jpg" ||
            value.type === "image/jpeg" ||
            value.type === "image/png")
        );
      }),

    right_image: Yup.mixed()
      .test("required", "Please upload right image", (value) => {
        return value != null;
      })
      .test("type", "Please select png, jpeg, jpg format", function (value) {
        return (
          value &&
          (value.type === "image/jpg" ||
            value.type === "image/jpeg" ||
            value.type === "image/png")
        );
      }),
  });

  const { values, setFieldValue, touched, errors, handleSubmit, resetForm } =
    useFormik({
      initialValues: {
        front_image: "",
        back_image: "",
        left_image: "",
        right_image: "",
      },
      validationSchema,
      onSubmit: (values) => {
        let formData = new FormData();
        formData.append("product_id", pid);
        formData.append("order_id", id);
        formData.append("front_image", values?.front_image);
        formData.append("back_image", values?.back_image);
        formData.append("left_image", values?.left_image);
        formData.append("right_image", values?.right_image);
        setLoading(true);
        productImages(formData).then((resp) => {
          if (resp?.data?.status === 200) {
            setLoading(false);
            setShowOne(false);
            ProductImageDetails();
            resetForm();
          }
        });
      },
    });

  const getLabel = () => {
    productLabel(product?.order_id).then((resp) => {
      window.location.href = resp.data?.data?.label_url;
    });
  };

  const ProductDetails = () => {
    setLoading(true);
    getProductDetailsAfterCheckout(id).then((resp) => {
      setLoading(false);

      if (resp?.data?.status === 200) {
        const tempArr = [];
        if (resp?.data?.data) {
          resp?.data?.data?.product_image?.map((data) => {
            if (data?.is_cover_photo) {
              tempArr.unshift({
                original: imageBase + data?.images,
                thumbnail: imageBase + data?.images,
              });
            } else {
              tempArr.push({
                original: imageBase + data?.images,
                thumbnail: imageBase + data?.images,
              });
            }
          });
        }
        setSliderImg(tempArr);
        setProduct(resp?.data?.data);

        setData(resp?.data);
        let body = {
          product_id: pid,
          from: resp?.data?.data?.booked_from,
          to: resp?.data?.data?.booked_to,
          deliver_to: resp?.data?.data?.deliver_to,
          cod: 0,
        };
        getPriceEstimation(body).then((res) => {
          if (res?.data?.status === 200) {
            setPriceDetails(res?.data);
          }
        });
      }
    });
  };

  const ProductImageDetails = () => {
    getProductImageDetails(id).then((resp) => {
      if (resp?.data?.status === 200) {
        setImage1(resp?.data?.data?.front_image);
        setImage2(resp?.data?.data?.back_image);
        setImage3(resp?.data?.data?.left_image);
        setImage4(resp?.data?.data?.right_image);
      }
    });
  };

  const handleMessageSubmit = (message) => {
    setShowMessageModal(false);

    // Execute the pending action with the captured message
    if (pendingAction === SELF_DELIVERY_REQUEST_ACCEPTED) {
      handleAcceptDeliveryRequest(pendingOrderId, message);
    } else if (pendingAction === SELF_DELIVERY_REQUEST_REJECTED) {
      handleRejectDeliveryRequest(pendingOrderId, message);
    }
  };

  const handleAcceptDeliveryRequest = (order_id, message) => {
    setLoading(true);
    const body = {
      order_id: order_id,
      action: SELF_DELIVERY_REQUEST_ACCEPTED,
      message: message,
    };
    actionDeliveryRequest(body)
      .then((resp) => {
        if (resp?.status === 200) {
          setRespondToRenter(SELF_DELIVERY_REQUEST_ACCEPTED);
          showNotification(
            "success",
            resp?.message || "Request approved successfully"
          );
        } else {
          showNotification(
            "error",
            resp?.message || "Failed to Update request"
          );
        }
      })
      .catch((error) => {
        showNotification("error", "Something went wrong");
      })
      .finally(() => {
        setLoading(false);
        setPendingAction(null);
        setPendingOrderId(null);
      });
  };

  const handleRejectDeliveryRequest = (order_id, message) => {
    setLoading(true);
    const body = {
      order_id: order_id,
      action: SELF_DELIVERY_REQUEST_REJECTED,
      message: message,
    };
    actionDeliveryRequest(body)
      .then((resp) => {
        if (resp?.status === 200) {
          setRespondToRenter(SELF_DELIVERY_REQUEST_REJECTED);
          showNotification(
            "success",
            resp?.message || "Request rejected successfully"
          );
        } else {
          showNotification(
            "error",
            resp?.message || "Failed to update request"
          );
        }
      })
      .catch((error) => {
        showNotification("error", "Something went wrong");
      })
      .finally(() => {
        setLoading(false);
        setPendingAction(null);
        setPendingOrderId(null);
      });
  };

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      <div className="category-detailview pb-3 pt-5">
        <Container>
          <Row>
            <Col lg={5} md={5} sm={12}>
              <div className="d-flex d-lg-none mb-4 align-items-center">
                <span className="me-3" onClick={() => navigate(-1)}>
                  <BackIcon />
                </span>
                <h4>{product?.title}</h4>
              </div>

              <ReactImageGallery
                items={sliderImg}
                showNav={true}
                showFullscreenButton={false}
                showPlayButton={false}
                pagination={{
                  clickable: true,
                  renderBullet: function (index, className) {
                    return (
                      '<span className="' +
                      className +
                      '"><img className="pagination-bullet"/></span>'
                    );
                  },
                }}
              />

              <div className="mt-2">
                {moment(product?.booked_to).format("YYYY-MM-DD") <
                  moment().format("YYYY-MM-DD") && (
                  <Link
                    className="btn btn-default w-100 "
                    to={
                      role == RENTER
                        ? `/renter/write-review?id=${id}&pid=${pid}`
                        : `/lender/write-review?id=${id}&pid=${pid}`
                    }
                  >
                    <WritereviewIcon /> Write reviews
                  </Link>
                )}
                {role == RENTER && (
                  <>
                    <Link
                      className="btn btn-default w-100 "
                      onClick={(e) => e.preventDefault()}
                    >
                      <GetReceiptIcon /> Get Receipt
                    </Link>

                    <Link
                      className="btn btn-default w-100 mt-2"
                      onClick={(e) => e.preventDefault()}
                    >
                      <TrackingIcon /> Tracking
                    </Link>
                  </>
                )}

                <Link
                  className="btn btn-default w-100 d-none d-md-block d-lg-block"
                  onClick={(e) => e.preventDefault()}
                >
                  <SupportIcon /> Support
                </Link>
              </div>
            </Col>

            <Col lg={7} md={7} sm={12}>
              <div className="detail-view">
                <h2 className="d-none d-lg-block">{product?.title} </h2>
                <Row className="d-flex flex-wrap justify-content-between">
                  <Col
                    lg={9}
                    md={9}
                    sm={12}
                    className="d-none flex-wrap d-lg-flex"
                  >
                    <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                      <div className="d-flex align-items-start">
                        <h6 className="font16 font700 colordark mb-0">
                          {role == RENTER ? "Lender" : "Renter"}
                        </h6>
                        <div
                          className="usrimg ms-2 mt-0"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (role == RENTER) {
                              navigate(
                                `/renter/user-profile?user_id=${product?.lender_id}`
                              );
                            } else {
                              navigate(
                                `/lender/user-profile?user_id=${product?.booked_by?.renter_id}`
                              );
                            }
                          }}
                        >
                          {role == RENTER ? (
                            <>
                              {product?.lender_profile_pic?.profile_pic ? (
                                <img
                                  src={
                                    product?.lender_profile_pic?.profile_pic
                                      ? imageBase +
                                        product?.lender_profile_pic?.profile_pic
                                      : require("../../assets/images/DummyImg.png")
                                  }
                                />
                              ) : (
                                <>
                                  <DefaultProfileImg
                                    userDetails={splitFullName(
                                      product?.created_by_name
                                    )}
                                  />
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {product?.renter_profile_pic?.profile_pic ? (
                                <img
                                  src={
                                    product?.renter_profile_pic?.profile_pic
                                      ? imageBase +
                                        product?.renter_profile_pic?.profile_pic
                                      : require("../../assets/images/DummyImg.png")
                                  }
                                />
                              ) : (
                                <>
                                  <DefaultProfileImg
                                    userDetails={splitFullName(
                                      product?.booked_by?.renter_name
                                    )}
                                  />
                                </>
                              )}
                            </>
                          )}
                        </div>

                        <div className="userinfo">
                          <h6
                            className="mb-0"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (role == RENTER) {
                                navigate(
                                  `/renter/user-profile?user_id=${product?.lender_id}`
                                );
                              } else {
                                navigate(
                                  `/lender/user-profile?user_id=${product?.booked_by?.renter_id}`
                                );
                              }
                            }}
                          >
                            {role == LENDER
                              ? product?.booked_by?.renter_name
                              : product?.created_by_name}
                            <span className="font12 font400">|</span>{" "}
                            <i>
                              <span className="font12 font400">
                                {product?.rental_count} Rentals Joined{" "}
                                {moment(userDetail?.date_joined).format("YYYY")}
                              </span>
                            </i>
                          </h6>
                          <div className="starlist ms-3">
                            <ReactStars
                              value={product?.average_rating}
                              count={5}
                              size={24}
                              color2={"#16ff96"}
                              edit={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {role == LENDER ? (
                    <>
                      {product?.delivery_options == SELF ? (
                        <>
                          {product?.request_status ===
                            SELF_DELIVERY_REQUEST_ACCEPTED ||
                          respondToRenter === SELF_DELIVERY_REQUEST_ACCEPTED ? (
                            <Link
                              to={`/lender/message`}
                              state={{
                                receiverId: product?.booked_by?.renter_id,
                                productId: product?.id,
                                orderId: product?.order_id,
                              }}
                              className="btn btn-theme float-end d-none d-md-block msg-btn"
                              id={id}
                            >
                              Message Renter
                            </Link>
                          ) : null}
                          {product?.request_status ==
                            SELF_DELIVERY_REQUEST_PENDING && (
                            <>
                              {product.message_from_renter && (
                                <p>
                                  <strong>Renter Message: &nbsp;</strong>
                                  {product.message_from_renter}
                                </p>
                              )}
                              {respondToRenter ? (
                                <p>
                                  {respondToRenter ==
                                  SELF_DELIVERY_REQUEST_ACCEPTED ? (
                                    <span className="badge bg-theme font12 font700 text-dark">
                                      Accepted
                                    </span>
                                  ) : (
                                    <span className="badge bg-danger font12 font700">
                                      Rejected
                                    </span>
                                  )}
                                </p>
                              ) : (
                                <span className="d-flex sd-btn-wrapper">
                                  <Button
                                    onClick={() =>
                                      openMessageModal(
                                        SELF_DELIVERY_REQUEST_ACCEPTED,
                                        product?.order_id
                                      )
                                    }
                                    className="btn-success me-2"
                                  >
                                    Accept
                                  </Button>
                                  <Button
                                    onClick={() =>
                                      openMessageModal(
                                        SELF_DELIVERY_REQUEST_REJECTED,
                                        product?.order_id
                                      )
                                    }
                                    className="btn-danger"
                                  >
                                    Reject
                                  </Button>
                                </span>
                              )}
                            </>
                          )}
                          {product?.request_status === SELF_DELIVERY_REQUEST_REJECTED ? (
                            <span className="badge bg-danger font12 font700 float-end" style={{ width: "auto", height: "max-content" }}>Rejected on{" "}
                              {moment(product?.responded_at).format(
                                "YYYY-MM-DD"
                              ) || ""}
                            </span>
                          ) : null}
                        </>
                      ) : null}
                    </>
                  ) : (
                    <Link
                      to={`/renter/message`}
                      state={{
                        receiverId: product?.lender_id,
                        productId: product?.id,
                        orderId: product?.order_id,
                      }}
                      className="btn btn-theme float-end d-none d-md-block msg-btn"
                      id={id}
                    >
                      Message Lender
                    </Link>
                  )}

                  {/******************************For Mobile Screen***************************/}
                  <Col lg={9} md={12} sm={12} className="d-block d-lg-none">
                    <h6 className="font16 font700 colordark mb-lg-0 mb-3">
                      {role == RENTER ? "Lender" : "Renter"}
                    </h6>
                    <div className="d-flex align-items-center justify-content-between mb-3 W-100">
                      <div className="d-flex align-items-start">
                        <div
                          className="usrimg ms-2 mt-0"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (role == RENTER) {
                              navigate(
                                `/renter/user-profile?user_id=${product?.lender_id}`
                              );
                            } else {
                              navigate(
                                `/lender/user-profile?user_id=${product?.booked_by?.renter_id}`
                              );
                            }
                          }}
                        >
                          {role == RENTER ? (
                            <img
                              src={
                                product?.lender_profile_pic?.profile_pic
                                  ? imageBase +
                                    product?.lender_profile_pic?.profile_pic
                                  : require("../../assets/images/DummyImg.png")
                              }
                            />
                          ) : (
                            <img
                              src={
                                product?.renter_profile_pic?.profile_pic
                                  ? imageBase +
                                    product?.renter_profile_pic?.profile_pic
                                  : require("../../assets/images/DummyImg.png")
                              }
                            />
                          )}
                        </div>

                        <div className="userinfo">
                          <h6
                            className="mb-0"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (role == RENTER) {
                                navigate(
                                  `/renter/user-profile?user_id=${product?.lender_id}`
                                );
                              } else {
                                navigate(
                                  `/lender/user-profile?user_id=${product?.booked_by?.renter_id}`
                                );
                              }
                            }}
                          >
                            {role == LENDER
                              ? product?.booked_by?.renter_name
                              : product?.created_by_name}
                            <span className="font12 font400">|</span>{" "}
                            <span className="font12 font400">
                              {product?.rental_count} Rentals Joined{" "}
                              {moment(userDetail?.date_joined).format("YYYY")}
                            </span>
                          </h6>
                          <div className="starlist ms-0">
                            <ReactStars
                              value={product?.average_rating}
                              count={5}
                              size={24}
                              color2={"#16ff96"}
                              edit={false}
                            />
                          </div>
                        </div>
                      </div>
                      {role == LENDER ? (
                        <Link
                          to={`/lender/message`}
                          state={{
                            receiverId: product?.booked_by?.renter_id,
                            productId: product?.id,
                            orderId: product?.order_id,
                          }}
                          className="btn btn-theme float-end message-btn "
                        >
                          Message Renter
                        </Link>
                      ) : (
                        <Link
                          to={`/renter/message`}
                          state={{
                            receiverId: product?.created_by?.created_by_id,
                            productId: product?.id,
                            orderId: product?.order_id,
                          }}
                          className="btn btn-theme float-end message-btn"
                        >
                          Message Lender
                        </Link>
                      )}
                    </div>
                  </Col>
                </Row>

                <h6 className="font16 font700 colordark mb-3">
                  Rental Details
                </h6>
                <Form>
                  <Row>
                    <Col xl={4} lg={4} md={6} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="font700 font12 secondaycolor">
                          From
                        </Form.Label>
                        <div className="datepicker position-relative">
                          <Form.Control
                            type="date"
                            placeholder="DD/MM/YYYY"
                            min={moment().format("YYYY-MM-DD")}
                            name="booked_from"
                            readOnly
                            value={moment(data?.data?.booked_from).format(
                              "YYYY-MM-DD"
                            )}
                          />
                          <span className="position-absolute">
                            <CalendarIcon />
                          </span>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="font700 font12 secondaycolor">
                          To
                        </Form.Label>
                        <div className="datepicker position-relative">
                          <Form.Control
                            type="date"
                            placeholder="DD/MM/YYYY"
                            name="booked_to"
                            readOnly
                            value={moment(data?.data?.booked_to).format(
                              "YYYY-MM-DD"
                            )}
                          />
                          <span className="position-absolute">
                            <CalendarIcon />
                          </span>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="font700 font12 secondaycolor">
                          Zip Code
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Zip Code"
                          value={data?.data?.deliver_to}
                          disabled={true}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
                <Row>
                  <Col lg={12}>
                    {role == RENTER ? (
                      <div className="noborder greytable p-3 mb-3">
                        <div
                          className={`d-flex align-items-center justify-content-between flex-wrap ${product?.delivery_options === SELF ? "" : "mb-lg-4 mb-2"}`}
                        >
                          <h6 className="font16 font700 colordark">
                            {product?.renter_delivery_carrier ||
                              "Delivery to renter by UPS"}
                          </h6>
                          <h6 className="font16 font700 colordark">
                            Receive it before:{" "}
                            <span className="ms-1">{product?.booked_from}</span>
                          </h6>
                        </div>
                        {product?.delivery_options != SELF ? (
                          <p className="font16 font400 colordark mb-0">
                            Tracking ID: #
                            {product?.tracking_id ? product?.tracking_id : null}
                          </p>
                        ) : null}
                      </div>
                    ) : (
                      <div className="noborder greytable p-3 mb-3">
                        <div
                          className={`d-flex align-items-center justify-content-between flex-wrap ${product?.delivery_options === SELF ? "" : "mb-lg-4 mb-2"}`}
                        >
                          <h6 className="font16 font700 colordark">
                            {product?.renter_delivery_carrier ||
                              "Delivery to renter by UPS"}
                          </h6>
                          <h6 className="font16 font700 colordark">
                            Send before:{" "}
                            <span className="ms-1">{product?.booked_from}</span>
                          </h6>
                        </div>
                        {product?.delivery_options != SELF ? (
                          <p className="font16 font400 colordark mb-0">
                            Tracking ID: #
                            {product?.tracking_id ? product?.tracking_id : null}
                          </p>
                        ) : null}
                      </div>
                    )}
                    {role == RENTER ? (
                      <div className="noborder greytable p-3 mb-3">
                        <div
                          className={`d-flex align-items-center justify-content-between flex-wrap ${product?.delivery_options === SELF ? "" : "mb-lg-4 mb-2"}`}
                        >
                          <h6 className="font16 font700 colordark">
                            {product?.renter_delivery_carrier ||
                              "Delivery to lender by UPS"}
                          </h6>
                          <h6 className="font16 font700 colordark">
                            Send back by:{" "}
                            <span className="ms-1">{product?.booked_to}</span>
                          </h6>
                        </div>
                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                          {product?.delivery_options != SELF ? (
                            <>
                              <p className="font16 font400 colordark mb-0">
                                Tracking ID: #
                                {product?.tracking_id
                                  ? product?.tracking_id
                                  : null}
                              </p>
                              <a
                                className="btn btn-theme my-lg-0 mt-3"
                                // onClick={handleShow}
                                onClick={() => {
                                  getLabel();
                                }}
                              >
                                Shipping Label
                              </a>
                            </>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      <div className="noborder greytable p-3 mb-3">
                        <div
                          className={`d-flex align-items-center justify-content-between flex-wrap ${product?.delivery_options === SELF ? "" : "mb-lg-4 mb-2"}`}
                        >
                          <h6 className="font16 font700 colordark">
                            {product?.renter_delivery_carrier ||
                              "Delivery to lender by UPS"}
                          </h6>
                          <h6 className="font16 font700 colordark">
                            Receive it back by:{" "}
                            <span className="ms-1">{product?.booked_to}</span>
                          </h6>
                        </div>
                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                          {product?.delivery_options != SELF ? (
                            <>
                              <p className="font16 font400 colordark mb-0">
                                Tracking ID: #
                                {product?.tracking_id
                                  ? product?.tracking_id
                                  : null}
                              </p>
                              <a
                                className="btn btn-theme my-lg-0 mt-3 d-none d-md-block"
                                onClick={(e) => {
                                  e.preventDefault();
                                  // handleShow();
                                }}
                              >
                                Print Shipping Label
                              </a>
                            </>
                          ) : null}
                        </div>
                      </div>
                    )}

                    <h6 className="font16 font700 colordark mb-3 d-none d-md-block">
                      {role == RENTER ? (
                        <>Your Payment summary</>
                      ) : (
                        <>Your Payout summary</>
                      )}
                    </h6>
                    <div className="d-none d-md-block">
                      <table className="noborder greytable w-100 table ">
                        <tbody>
                          <tr>
                            <td>
                              Sub Total (
                              {priceDetails?.total_rental_days?.join(" ") ??
                                "0 days"}
                              )
                            </td>
                            <td className="text-end">
                              {DEFAULT_CURRENCY} {}
                              {role === LENDER
                                ? data?.data?.delivery_options === SELF
                                  ? data?.data?.sub_total &&
                                    data?.data?.shaxify_fee?.shaxify_renter_fee
                                    ? Number(
                                        data?.data?.sub_total +
                                          data?.data?.shaxify_fee
                                            ?.shaxify_renter_fee
                                      )?.toLocaleString("hi")
                                    : 0
                                  : data?.data?.sub_total
                                    ? Number(
                                        data?.data?.sub_total
                                      )?.toLocaleString("hi")
                                    : 0
                                : role === RENTER
                                  ? (data?.data?.sub_total
                                      ? Number(data?.data?.sub_total)
                                      : 0
                                    )?.toLocaleString("hi")
                                  : 0}
                            </td>
                          </tr>

                          {(() => {
                            const discountPercentage =
                              priceDetails?.discount_applied
                                ? parseFloat(
                                    priceDetails.discount_applied.replace(
                                      "%",
                                      ""
                                    )
                                  )
                                : 0;
                            const subTotal = data?.data?.sub_total
                              ? Number(data.data.sub_total)
                              : 0;
                            const discountAmount =
                              (subTotal * discountPercentage) / 100;

                            // Return the JSX within the IIFE
                            return (
                              priceDetails?.discount_days && (
                                <tr>
                                  <td>
                                    {priceDetails?.discount_days} Discount (
                                    {priceDetails?.discount_applied})
                                  </td>
                                  <td className="text-end">
                                    {DEFAULT_CURRENCY} {}
                                    {discountAmount?.toLocaleString("hi")}
                                  </td>
                                </tr>
                              )
                            );
                          })()}

                          <tr>
                            <td>Shaxify fee </td>
                            <td className="text-end">
                              {DEFAULT_CURRENCY}{" "}
                              {role == LENDER ? (
                                <>
                                  {/* If delivery type is self then show shaxify fee for both lender and renter. Because we will charge whole platform commission from the lender */}
                                  {data?.data?.delivery_options === SELF
                                    ? data?.data?.shaxify_fee
                                        ?.shaxify_lender_fee &&
                                      data?.data?.shaxify_fee
                                        ?.shaxify_renter_fee
                                      ? Number(
                                          data?.data?.shaxify_fee
                                            ?.shaxify_lender_fee +
                                            data?.data?.shaxify_fee
                                              ?.shaxify_renter_fee
                                        )?.toLocaleString("hi")
                                      : 0
                                    : data?.data?.shaxify_fee
                                          ?.shaxify_lender_fee
                                      ? Number(
                                          data?.data?.shaxify_fee
                                            ?.shaxify_lender_fee
                                        )?.toLocaleString("hi")
                                      : 0}
                                </>
                              ) : (
                                <>
                                  {data?.data?.shaxify_fee?.shaxify_renter_fee
                                    ? Number(
                                        data?.data?.shaxify_fee
                                          ?.shaxify_renter_fee
                                      )?.toLocaleString("hi")
                                    : 0}
                                </>
                              )}
                            </td>
                          </tr>
                          {role == RENTER ? (
                            <tr>
                              <td>Estimated Shipping</td>
                              <td className="text-end">
                                {DEFAULT_CURRENCY}{" "}
                                {data?.data?.shipping_fee
                                  ? Number(
                                      data?.data?.shipping_fee
                                    )?.toLocaleString("hi")
                                  : 0}
                              </td>
                            </tr>
                          ) : null}
                          <tr>
                            <td className="font700">Total</td>
                            <td className="text-end font700">
                              {DEFAULT_CURRENCY}{" "}
                              {role == LENDER ? (
                                <>
                                  {/* Formula for lender panel only : Total = Subtotal - Discounts - Lender Shaxify Fee. */}
                                  {(() => {
                                    let discountValue = 0;

                                    if (
                                      priceDetails?.discount_applied &&
                                      typeof priceDetails.discount_applied ===
                                        "string" &&
                                      priceDetails.discount_applied.includes(
                                        "%"
                                      )
                                    ) {
                                      discountValue =
                                        parseFloat(
                                          priceDetails.discount_applied.replace(
                                            "%",
                                            ""
                                          )
                                        ) / 100;
                                    }

                                    const platformCommission =
                                      data?.data?.shaxify_fee
                                        ?.shaxify_lender_fee;

                                    return data?.data?.sub_total
                                      ? Number(
                                          data?.data?.sub_total -
                                            (data?.data?.sub_total *
                                              discountValue || 0) -
                                            // (data?.data?.shaxify_fee?.shaxify_lender_fee || 0)
                                            platformCommission
                                        )?.toLocaleString("hi")
                                      : 0;
                                  })()}
                                </>
                              ) : (
                                <>
                                  {data?.data?.total_amount
                                    ? Number(
                                        data?.data?.total_amount
                                      )?.toLocaleString("hi")
                                    : 0}
                                </>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <table className="noborder greytable w-100 table d-md-block d-lg-none">
                      {role == LENDER ? (
                        <h6 className="font16 font700 colordark mb-0 p-2">
                          Your Payout summary
                        </h6>
                      ) : (
                        <h6 className="font16 font700 colordark mb-0 p-2">
                          Your Payment summary
                        </h6>
                      )}

                      <tbody>
                        <tr>
                          <td>
                            Sub Total (
                            {priceDetails?.total_rental_days?.join(" ") ??
                              "0 days"}
                            )
                          </td>
                          <td className="text-end">
                            {DEFAULT_CURRENCY} {}
                            {data?.data?.delivery_options === SELF
                              ? data?.data?.sub_total &&
                                data?.data?.shaxify_fee?.shaxify_renter_fee
                                ? Number(
                                    data?.data?.sub_total +
                                      data?.data?.shaxify_fee
                                        ?.shaxify_renter_fee
                                  )?.toLocaleString("hi")
                                : 0
                              : data?.data?.sub_total
                                ? Number(data?.data?.sub_total)?.toLocaleString(
                                    "hi"
                                  )
                                : 0}
                          </td>
                        </tr>
                        {(() => {
                          const discountPercentage =
                            priceDetails?.discount_applied
                              ? parseFloat(
                                  priceDetails.discount_applied.replace("%", "")
                                )
                              : 0;
                          const subTotal = data?.data?.sub_total
                            ? Number(data.data.sub_total)
                            : 0;
                          const discountAmount =
                            (subTotal * discountPercentage) / 100;

                          // Return the JSX within the IIFE
                          return (
                            priceDetails?.discount_days && (
                              <tr>
                                <td>
                                  {priceDetails?.discount_days} Discount (
                                  {priceDetails?.discount_applied})
                                </td>
                                <td className="text-end">
                                  {DEFAULT_CURRENCY} {}
                                  {discountAmount?.toLocaleString("hi")}
                                </td>
                              </tr>
                            )
                          );
                        })()}
                        <tr>
                          <td>Shaxify fee</td>
                          <td className="text-end">
                            {DEFAULT_CURRENCY}{" "}
                            {role == LENDER ? (
                              <>
                                {/* If delivery type is self then show shaxify fee for both lender and renter. Because we will charge whole platform commission from the lender */}
                                {data?.data?.delivery_options === SELF
                                  ? data?.data?.shaxify_fee
                                      ?.shaxify_lender_fee &&
                                    data?.data?.shaxify_fee?.shaxify_renter_fee
                                    ? Number(
                                        data?.data?.shaxify_fee
                                          ?.shaxify_lender_fee +
                                          data?.data?.shaxify_fee
                                            ?.shaxify_renter_fee
                                      )?.toLocaleString("hi")
                                    : 0
                                  : data?.data?.shaxify_fee?.shaxify_lender_fee
                                    ? Number(
                                        data?.data?.shaxify_fee
                                          ?.shaxify_lender_fee
                                      )?.toLocaleString("hi")
                                    : 0}
                              </>
                            ) : (
                              <>
                                {data?.data?.shaxify_fee?.shaxify_renter_fee
                                  ? Number(
                                      data?.data?.shaxify_fee
                                        ?.shaxify_renter_fee
                                    )?.toLocaleString("hi")
                                  : 0}
                              </>
                            )}
                          </td>
                        </tr>
                        {role == RENTER ? (
                          <tr>
                            <td>Estimated Shipping</td>
                            <td className="text-end">
                              {DEFAULT_CURRENCY}{" "}
                              {data?.data?.shipping_fee
                                ? Number(
                                    data?.data?.shipping_fee
                                  )?.toLocaleString("hi")
                                : 0}
                            </td>
                          </tr>
                        ) : null}
                        <tr>
                          <td className="font700">Total</td>
                          <td className="text-end font700">
                            {DEFAULT_CURRENCY}{" "}
                            {role == LENDER ? (
                              <>
                                {/* Formula for lender panel only : Total = Subtotal - Discounts - Lender Shaxify Fee. */}
                                {(() => {
                                  let discountValue = 0;

                                  if (
                                    priceDetails?.discount_applied &&
                                    typeof priceDetails.discount_applied ===
                                      "string" &&
                                    priceDetails.discount_applied.includes("%")
                                  ) {
                                    discountValue =
                                      parseFloat(
                                        priceDetails.discount_applied.replace(
                                          "%",
                                          ""
                                        )
                                      ) / 100;
                                  }

                                  const platformCommission =
                                    data?.data?.shaxify_fee?.shaxify_lender_fee;

                                  return data?.data?.sub_total
                                    ? Number(
                                        data?.data?.sub_total -
                                          (data?.data?.sub_total *
                                            discountValue || 0) -
                                          // (data?.data?.shaxify_fee?.shaxify_lender_fee || 0)
                                          platformCommission
                                      )?.toLocaleString("hi")
                                    : 0;
                                })()}
                              </>
                            ) : (
                              <>
                                {data?.data?.total_amount
                                  ? Number(
                                      data?.data?.total_amount
                                    )?.toLocaleString("hi")
                                  : 0}
                              </>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <Row className="align-items-center mt-4">
                      <Col lg={8} sm={6} xs={6}>
                        <p className="font12 font700">
                          Due to insurance purposes we need you to take pictures
                          of the item 12h after receiving the item.
                        </p>
                      </Col>
                      <Col lg={4} sm={6} xs={6}>
                        <Button
                          onClick={handleShowOne}
                          className="btn btn-theme w-100 mb-0"
                        >
                          {role == RENTER &&
                          !image1 &&
                          !image2 &&
                          !image3 &&
                          !image4
                            ? "Take pictures"
                            : "View pictures"}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />

      {/* Print slip modal */}

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="text-end p-2">
          <Link
            onClick={(e) => {
              e.preventDefault();
              handleCloseTwo();
            }}
          >
            <CrossIcon />
          </Link>
        </div>
        <Modal.Body>
          <div className="slip-img">
            {labelapiResponse && (
              <>
                <div>
                  <p>Order ID: {labelapiResponse.data.order_id}</p>
                  <p>Shipment ID: {labelapiResponse.data.shipment_id}</p>
                </div>
                <div className="text-center">
                  <button className="btn btn-theme mt-3 mb-1 w-25">
                    <a
                      href={labelapiResponse.data.label_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {/* <p> Download Shipping Label</p> */}
                      Print
                    </a>
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>

      {/* Take picture modal */}
      <Modal
        show={showOne}
        onHide={handleCloseOne}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="picture-modal"
      >
        <div className="text-end p-2 me-3">
          <Link
            onClick={(e) => {
              e.preventDefault();
              handleCloseOne();
            }}
          >
            <CrossIcon />
          </Link>
        </div>
        <Modal.Body>
          <Row>
            <Col lg={6} sm={12}>
              <div className="text-center mb-4">
                <h4 className="mb-2">Front Picture</h4>
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    className="d-none"
                    onChange={(e) => {
                      setFieldValue(
                        "front_image",
                        e.target.files[0]?.type?.includes("image")
                          ? e.target.files[0]
                          : ""
                      );
                      setImage1("");
                    }}
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                    id="image1"
                  />
                  <div>
                    <img
                      className="placeholder-img"
                      src={
                        image1
                          ? imageBase + image1
                          : values?.front_image
                            ? URL.createObjectURL(values?.front_image)
                            : require("../../assets/images/static_image.jpg")
                      }
                      alt="img"
                    />
                  </div>
                  <p className="font14 text-center mb-1 text-danger">
                    {touched?.front_image && errors?.front_image}
                  </p>
                  {role == RENTER && !image1 && (
                    <label
                      htmlFor="image1"
                      className="btn btn-theme-outline font700"
                    >
                      Take picture
                    </label>
                  )}
                </div>
              </div>
            </Col>
            <Col lg={6} sm={12}>
              <div className="text-center mb-4">
                <h4 className="mb-2">Back Picture</h4>
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    className="d-none"
                    onChange={(e) => {
                      setFieldValue(
                        "back_image",
                        e.target.files[0]?.type?.includes("image")
                          ? e.target.files[0]
                          : ""
                      );
                      setImage2("");
                    }}
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                    id="image2"
                  />

                  <div>
                    <img
                      className="placeholder-img"
                      src={
                        image2
                          ? imageBase + image2
                          : values?.back_image
                            ? URL.createObjectURL(values?.back_image)
                            : require("../../assets/images/static_image.jpg")
                      }
                      alt="img"
                    />
                  </div>
                  <p className="font14 text-center mb-1 text-danger">
                    {touched?.back_image && errors?.back_image}
                  </p>
                  {role == RENTER && !image2 && (
                    <label
                      htmlFor="image2"
                      className="btn btn-theme-outline font700"
                    >
                      Take picture
                    </label>
                  )}
                </div>
              </div>
            </Col>
            <Col lg={6} sm={12}>
              <div className="text-center mb-4">
                <h4 className="mb-2">Left Side</h4>
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    className="d-none"
                    onChange={(e) => {
                      setFieldValue(
                        "left_image",
                        e.target.files[0]?.type?.includes("image")
                          ? e.target.files[0]
                          : ""
                      );
                      setImage3("");
                    }}
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                    id="image3"
                  />
                  <div>
                    <img
                      className="placeholder-img"
                      src={
                        image3
                          ? imageBase + image3
                          : values?.left_image
                            ? URL.createObjectURL(values?.left_image)
                            : require("../../assets/images/static_image.jpg")
                      }
                      alt="img"
                    />
                  </div>
                  <p className="font14 text-center mb-1 text-danger">
                    {touched?.left_image && errors?.left_image}
                  </p>
                  {role == RENTER && !image3 && (
                    <label
                      htmlFor="image3"
                      className="btn btn-theme-outline font700"
                    >
                      Take picture
                    </label>
                  )}
                </div>
              </div>
            </Col>

            <Col lg={6} sm={12}>
              <div className="text-center mb-4">
                <h4 className="mb-2">Right Side</h4>
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    className="d-none"
                    onChange={(e) => {
                      setFieldValue(
                        "right_image",
                        e.target.files[0]?.type?.includes("image")
                          ? e.target.files[0]
                          : ""
                      );
                      setImage4("");
                    }}
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                    id="image4"
                  />
                  <div>
                    <img
                      className="placeholder-img"
                      src={
                        image4
                          ? imageBase + image4
                          : values?.right_image
                            ? URL.createObjectURL(values?.right_image)
                            : require("../../assets/images/static_image.jpg")
                      }
                      alt="img"
                    />
                  </div>
                  <p className="font14 text-center mb-1 text-danger">
                    {touched?.right_image && errors?.right_image}
                  </p>
                  {role == RENTER && !image4 && (
                    <label
                      htmlFor="image4"
                      className="btn btn-theme-outline font700"
                    >
                      Take picture
                    </label>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <p className="font12 font700 color-dark">
            We need you to take pictures of the item status within the first 12
            hours after receiving the item. This is for insurance purposes and
            any claim of damages.
          </p>
          <Col lg={4} sm={6} xs={6}>
            {role == RENTER && !image1 && !image2 && !image3 && !image4 ? (
              <Button
                onClick={handleSubmit}
                className="btn btn-theme w-100 mb-0 float right"
              >
                Save
              </Button>
            ) : (
              <Button
                onClick={handleCloseOne}
                className="btn btn-theme w-100 mb-0 float right"
              >
                Close
              </Button>
            )}
          </Col>
        </Modal.Body>
      </Modal>
      {loading && <Loader />}

      <MessageModal
        show={showMessageModal}
        onHide={() => {
          setShowMessageModal(false);
          setPendingAction(null);
          setPendingOrderId(null);
        }}
        onSubmit={handleMessageSubmit}
        role={"lender"}
      />
    </>
  );
};

export default ReservationDetail;
