/**
@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. 
***/
import { differenceInCalendarDays } from "date-fns";
import { useFormik } from "formik";
import moment from "moment/moment";
import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactStars from "react-stars";
import * as Yup from "yup";
import Footer from "../../Commons/Footer";
import Header from "../../Commons/Headers/Header";
import LenderHeader from "../../Commons/Headers/LenderHeader";
import RenterHeader from "../../Commons/Headers/RenterHeader";
import Loader from "../../Commons/Loader/Loader";
import { getCart } from "../../Redux/cartListSlice";
import {
  LanderProductDetails,
  addToCart,
  availavlityDetailsPerDay,
  getPriceEstimation,
  saveProduct,
} from "../../Services/APIServices";
import showNotification from "../../Services/NotificationService";
import {
  BackIcon,
  BookmarkIcon,
  BookmarkIconActive,
  CalendarIcon,
  CrossIcon,
  StarsBlueIcon,
  SupportIcon
} from "../../SvgIcons/allIcons";
import { imageBase } from "../../globals/Config";
import { DEFAULT_CURRENCY, LENDER, RENTER } from "../../globals/constants";
import useRole from "../../hooks/useRole";
import { pinCodes } from "../../utils/pincode";
import { ContactSupport } from "../CommonModals/ContactSupport";
import DefaultProfileImg from "../../Commons/DefaultProfileImg";
import ItemLocation from "./ItemLocation";
import { splitFullName } from "../../globals/HelperCommon";
/**
 * Single List view product > use for 3 conditions
 *
 *
 * @returns
 */

const RenterDetails = () => {
  const userDetail = useSelector((state) => state?.Login?.userDetails);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [month, setMonth] = useState(new Date());
  const [product, setProduct] = useState("");
  const [range, setRange] = useState();
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const id = new URLSearchParams(location?.search).get("id");
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleCloseTwo = () => setShow(false);
  const [showOne, setShowOne] = useState(false);
  const handleCloseOne = () => setShowOne(false);
  const handleShowOne = () => setShowOne(true);
  const [disableDates, setDisableDates] = useState([]);
  const [dailyPriceList, setDailyPriceList] = useState([]);
  const [sliderImg, setSliderImg] = useState([]);
  const role = useRole();
  const [loading, setLoading] = useState(false);
  const [checkoutDetails, setCheckoutDetails] = useState("");
  const checkLoggedInUser = useSelector((state) => state?.Login?.login);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedOptions, setSelecetedOptions] = useState("");
  const [showSupport, setShowSupport] = useState(false);

  const handleClose = () => {
    setShow(false)
    setShowSupport(false)
  };

  const handleShowSupport = () => setShowSupport(true);

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    ProductDetails();
    AvailabilityDetails(moment(month).format("MM"));
  }, [month]);

  function isPastDate(date) {
    return differenceInCalendarDays(date, new Date()) < 0;
  }

  const validationSchema = Yup.object({
    fromDate: Yup.date().required("From Date Required"),
    toDate: Yup.date().required("To Date Required"),
    // deliver_to: Yup.string()
    //   .required()
    //   .label("Zip code")
    //   .matches(/^[0-9]+$/, "Invalid zip code")
    //   .min(6, "Zip code must be at least 6 digits")
    //   .max(10),
  });

  const {
    values,
    handleSubmit,
    touched,
    errors,
    setFieldValue,
    handleChange,
    handleBlur,
  } = useFormik({
    initialValues: {
      fromDate: "",
      toDate: "",
      deliver_to: "",
    },
    validationSchema,
    onSubmit: () => {
      if (selectedOptions == "rent") {
        handleRentNow();
      } else {
        handleCheckPin();
      }
    },
  });

  const handleCheckPin = async () => {
    //if (pinCodes?.includes(+values.deliver_to)) {
    let body = {
      product_id: id,
      from: values?.fromDate,
      to: values?.toDate,
      deliver_to: values?.deliver_to ? values?.deliver_to : "",
      cod: 0,
    };

    let startDate = new Date(values?.fromDate);
    let endDate = new Date(values?.toDate);
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      for (let i = 0; i < disableDates.length; i++) {
        if (disableDates[i]?.getTime() == currentDate?.getTime()) {
          showNotification("danger", "Product not available for these days.");
          return false;
        }
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    setLoading(true);
    getPriceEstimation(body).then((resp) => {
      setLoading(false);
      if (resp?.status == 200) {
        setCheckoutDetails(resp?.data);
      } else {
        setFieldValue("fromDate", "");
        setFieldValue("toDate", "");
        setFieldValue("deliver_to", "");
      }
    });
    //} else {
    //  showNotification("danger", "Invalid zipcode");
    // }
  };

  const ProductDetails = () => {
    LanderProductDetails(id).then((resp) => {
      if (resp?.data?.status === 200) {
        setProduct(resp?.data?.data);
        const tempArr = [];
        if (resp?.data?.data) {
          resp?.data?.data?.product_image?.map((data) => {
            if (data?.is_cover_photo) {
              tempArr.unshift({
                original: imageBase + data?.images,
                thumbnail: imageBase + data?.images,
              });
            } else {
              tempArr.push({
                original: imageBase + data?.images,
                thumbnail: imageBase + data?.images,
              });
            }
          });
        }
        setSliderImg(tempArr);
      }
    });
  };

  const AvailabilityDetails = async (month) => {
    try {

      const resp = await availavlityDetailsPerDay(id, month, moment(month).format("YYYY"));
      if (resp?.data?.status === 200) {
        let blockDates = [];
        let dailyPrice = [];

        const { selected_dates, booked_data, product_price } = resp?.data?.data || {};

        Object.keys(selected_dates).forEach(date => {
          if (selected_dates[date].availability_status === false) {
            blockDates.push(new Date(date));
          } else if (selected_dates[date].availability_status === true) {
            dailyPrice.push({
              price: selected_dates[date]?.product_price ?? product_price ?? 0,
              date: new Date(date),
            });
          }
        });

        // Handle booked dates and extend with return buffer days
        booked_data.forEach((data) => {
          let startDate = new Date(data?.from_date);
          let endDate = new Date(data?.to_date);
          let bufferDays = data?.estimated_return_delivery_days || 0;
          let currentDate = new Date(startDate);

          // Block buffer days before the booking starts
          for (let i = 0; i < bufferDays; i++) {
            startDate.setDate(startDate.getDate() - 1);
            blockDates.push(new Date(startDate));
          }
          // Block all dates within the booking period
          while (currentDate <= endDate) {
            blockDates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
          }

          // Block buffer days after the booking ends
          for (let i = 0; i < bufferDays; i++) {
            endDate.setDate(endDate.getDate() + 1);
            blockDates.push(new Date(endDate));
          }
        });

        setDisableDates(blockDates);
        setDailyPriceList(dailyPrice);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  function renderDay(day) {

    const year = day.date.getFullYear();
    const month = day.date.getMonth();
    const date = day.date.getDate();

    const item = dailyPriceList.find((i) => {
      const itemDate = new Date(i.date);
      return (
        itemDate.getFullYear() === year &&
        itemDate.getMonth() === month &&
        itemDate.getDate() === date
      );
    });

    const price = item?.price ?? product.daily_price; // if no date specific price show default product price
    return (
      <div>
        <div>{date}</div>
        <div style={{ fontSize: "8px" }}>
          {price &&
            `${DEFAULT_CURRENCY}${Number(price).toLocaleString("hi")}`}
        </div>
      </div>
    );
  }

  const SaveProductHandler = (e) => {
    e.preventDefault();
    let body = {
      product_id: id,
      status: product?.is_saved == false ? 1 : 0,
    };
    let formData = new FormData();
    Object.keys(body).forEach((i) => {
      formData.append(i, body[i]);
    });
    if (checkLoggedInUser) {
      saveProduct(formData).then((resp) => {
        if (resp?.data?.status === 200) {
          ProductDetails();
        }
      });
    } else {
      document.getElementById("headerlogin").click();
    }
  };

  const handleRentNow = async (type) => {
    if (checkLoggedInUser) {
      // if (pinCodes?.includes(+values.deliver_to)) {
      if (type == "rent") {
        navigate(
          `/renter/checkout?id=${id}&from=${values?.fromDate}&to=${values?.toDate}&pincode=${values?.deliver_to}`,
          { state: checkoutDetails }
        );
      } else {
        let body = {
          product_id: id,
          saved_from: values?.fromDate,
          saved_to: values?.toDate,
          product_price: values?.total,
          deliver_to: values?.deliver_to,
        };
        addToCart(body).then((res) => {
          if (res?.status === 200) {
            dispatch(getCart());
            showNotification("success", res?.data?.message);
          } else {
            showNotification("err", res?.data?.message);
          }
        });
      }
      // } else {
      //   showNotification("danger", "Invalid zipcode");
      // }
    } else {
      document.getElementById("headerlogin").click();
    }
  };

  const handleViewProfile = () => {
    if (checkLoggedInUser) {
      navigate(`/renter/user-profile?user_id=${product?.created_by?.id}`);
    } else {
      document.getElementById("headerlogin").click();
    }
  };

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      <div className="mainbox_outer">
        <div className="category-detailview pb-3 pt-5 mb-3">
          <Container>
            <Row>
              <Col lg={5} md={5} sm={12} className="position-relative">
                <div className="d-flex d-lg-none mb-4 align-items-center">
                  <span className="me-3" onClick={() => navigate(-1)}>
                    <BackIcon />
                  </span>

                  <h4>{product?.title}</h4>
                </div>
                <div className="position-relative">
                  {product?.is_saved == false ? (
                    <div className="bookmark-icon ">
                      <Link
                        title="save"
                        onClick={(e) => {
                          e.preventDefault();
                          SaveProductHandler(e);
                        }}
                      >
                        <BookmarkIcon />
                      </Link>
                    </div>
                  ) : (
                    <div className="bookmark-icon ">
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          SaveProductHandler(e);
                        }}
                        title="unsave"
                      >
                        <BookmarkIconActive />
                      </Link>
                    </div>
                  )}

                  <ImageGallery
                    items={sliderImg}
                    showNav={true}
                    showBullets={true}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    navigation={true}
                    pagination={{
                      clickable: true,
                      renderBullet: function (index, className) {
                        return (
                          '<span className="' +
                          className +
                          '"><img className="pagination-bullet"/></span>'
                        );
                      },
                    }}
                  />
                </div>
                <br />
                <div className="d-none d-lg-block">
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      handleShowSupport();
                    }}
                    className="btn btn-default w-100"
                  >
                    <SupportIcon /> Support
                  </Link>
                </div>

                <div className="d-none d-md-block">
                  {product?.address?.latitude && product?.address?.longitude ? (
                    <ItemLocation lat={product?.address?.latitude ? product.address.latitude : ''} lng={product?.address?.longitude ? product.address.longitude : ''} />
                  ) : null}
                </div>
              </Col>

              <Col lg={7} md={7} sm={12} className="d-flex">
                <div className="detail-view d-flex flex-column justify-content-between height-inherit">
                    <div className="d-block">
                      <h3 className="d-none d-lg-block text-capitalize">
                        {product?.title}
                      </h3>
                      <Row className="d-flex flex-wrap">
                        <Col lg={12} md={12} sm={12} className="d-none flex-wrap d-lg-flex">
                          <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                            <div className="d-flex align-items-start">
                              <div
                                className="usrimg ms-2 mt-0"
                                style={{ cursor: "pointer" }}
                                onClick={handleViewProfile}
                              >
                                {product?.created_by?.profile_pic ? (
                                  <img
                                    src={
                                      product?.created_by?.profile_pic
                                        ? imageBase + product?.created_by?.profile_pic
                                        : require("../../assets/images/DummyImg.png")
                                    }
                                  />
                                ) : (
                                  <DefaultProfileImg userDetails={splitFullName(product?.created_by?.full_name)} />
                                )}
                              </div>

                              <div className="userinfo">
                                <h6
                                  className="mb-0"
                                  onClick={handleViewProfile}
                                  style={{ cursor: "pointer" }}
                                >
                                  {product?.created_by?.full_name} (
                                  {product?.rental_count}){" "}
                                  <span className="font12 font400">
                                    <i>
                                      {product?.rental_count} Rentals Joined{" "}
                                      {moment(userDetail?.date_joined).format(
                                        "YYYY"
                                      )}
                                    </i>
                                  </span>
                                </h6>
                                <div className="starlist">
                                  <ReactStars
                                    className="star_rating"
                                    size={24}
                                    count={5}
                                    value={product?.average_rating}
                                    half={false}
                                    edit={false}
                                    color2={"#16ff96"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={9} md={9} sm={12} className="d-block d-lg-none">
                          <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                            <div className="d-flex align-items-start">
                              <div
                                className="usrimg ms-2 mt-0"
                                onClick={handleViewProfile}
                              >
                                {product?.created_by?.profile_pic ? (
                                  <img
                                    src={
                                      product?.created_by?.profile_pic
                                        ? imageBase + product?.created_by?.profile_pic
                                        : require("../../assets/images/DummyImg.png")
                                    }
                                  />
                                ) : (
                                  <DefaultProfileImg userDetails={splitFullName(product?.created_by?.full_name)} />
                                )}
                              </div>

                              <div className="userinfo">
                                <h6 className="mb-0" onClick={handleViewProfile}>
                                  {product?.created_by?.full_name} (
                                  {product?.rental_count}){" "}
                                  <span className="font13 font400">|</span>
                                  <i>
                                    <span className="font12 font400">
                                      {product?.rental_count} Rentals Joined{" "}
                                      {moment(userDetail?.date_joined).format(
                                        "YYYY"
                                      )}
                                    </span>
                                  </i>
                                  <br />
                                </h6>
                                <div className="starlist ms-0">
                                  <ReactStars
                                    value={product?.average_rating}
                                    count={5}
                                    size={24}
                                    color2={"#16ff96"}
                                    edit={false}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <h6 className="mb-3 text-capitalize">
                        {product?.description}
                      </h6>
                    </div>

                    <div className="d-block">
                      <h6 className="font14 font700 colordark mb-2">
                        Product Measurements
                      </h6>
                      <Row>
                        <Col lg={3} md={6} sm={6} xs={6}>
                          <Form.Group className="mb-3">
                            <Form.Label className="font700 font12 secondaycolor">
                              Height (cm)
                            </Form.Label>
                            <div className="datepicker position-relative">
                              {product?.height}
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg={3} md={6} sm={6} xs={6}>
                          <Form.Group className="mb-3">
                            <Form.Label className="font700 font12 secondaycolor">
                              Width (cm)
                            </Form.Label>
                            <div className="datepicker position-relative">
                              {product?.width}
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg={3} md={6} sm={6} xs={6}>
                          <Form.Group className="mb-3">
                            <Form.Label className="font700 font12 secondaycolor">
                              Length (cm)
                            </Form.Label>
                            <div className="datepicker position-relative">
                              {product?.length}
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg={3} md={6} sm={6} xs={6}>
                          <Form.Group className="mb-3">
                            <Form.Label className="font700 font12 secondaycolor">
                              Weight (kg)
                            </Form.Label>
                            <div className="datepicker position-relative">
                              {product?.weight}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>

                      {/* Only for mobile */}
                      <div className="d-block d-md-none">
                        {product?.address?.latitude && product?.address?.longitude ? (
                          <ItemLocation lat={product?.address?.latitude ? product.address.latitude : ''} lng={product?.address?.longitude ? product.address.longitude : ''} />
                        ) : null}
                      </div>

                      <div>
                        <b>
                          <h3 className=" d-lg-block">
                            Today Price : {DEFAULT_CURRENCY}{" "}
                            {`${Number(
                              product?.daily_price ? product?.daily_price : 0
                            ).toLocaleString("hi")}`}
                          </h3>
                        </b>
                      </div>

                      <Form>
                        <Row>
                          <Col lg={6} md={6} sm={12}>
                            <Form.Group className={!errors?.fromDate ? "mb-3" : ''}>
                              <Form.Label className="font700 font12 secondaycolor">
                                From
                              </Form.Label>
                              <div
                                className="input-box position-relative"
                                onClick={() => setShowCalendar(!showCalendar)}
                              >
                                <p className="mb-0">
                                  {values?.fromDate
                                    ? moment(values?.fromDate).format("DD/MM/YYYY")
                                    : "DD/MM/YYYY"}
                                </p>
                                <CalendarIcon />
                              </div>
                              <span className="text-start required">
                                {errors?.fromDate}
                              </span>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6} sm={12}>
                            <Form.Group className={!errors?.fromDate ? "mb-3" : ''}>
                              <Form.Label className="font700 font12 secondaycolor">
                                To
                              </Form.Label>
                              <div
                                className="input-box position-relative"
                                onClick={() => setShowCalendar(!showCalendar)}
                              >
                                <p className="mb-0">
                                  {values?.toDate
                                    ? moment(values?.toDate).format("DD/MM/YYYY")
                                    : "DD/MM/YYYY"}
                                </p>
                                <CalendarIcon />
                              </div>
                              <span className="text-start required">
                                {errors?.toDate}
                              </span>
                            </Form.Group>
                          </Col>

                          {/* Remove zipcode */}
                          {/* <Col lg={4} md={6} sm={12}>
                            <Form.Group className={!errors?.fromDate ? "mb-3" : ''}>
                              <Form.Label className="font700 font12 secondaycolor">
                                Zip code
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Zip Code"
                                name="deliver_to"
                                value={values?.deliver_to}
                                onChange={(e) => {
                                  handleChange(e);
                                  setCheckoutDetails("");
                                }}
                                onBlur={handleBlur}
                                maxLength={10}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              <span className="text-start required">
                                {touched?.deliver_to && errors?.deliver_to}
                              </span>
                            </Form.Group>
                          </Col> */}

                          {showCalendar && (
                            <Col md={12}>
                              <DayPicker
                                month={month}
                                onMonthChange={(e) => {
                                  setMonth(e);
                                  AvailabilityDetails(moment(e).format("MM"));
                                }}
                                mode="range"
                                min={product?.minimum_rental_period}
                                max={product?.maximum_rental_period}
                                hidden={isPastDate}
                                selected={range}
                                onSelect={(e) => {
                                  if (e?.from && e?.to) {
                                    // Check if the selected range overlaps with blocked dates
                                    const isRangeBlocked = disableDates.some((blockedDate) => {
                                      const blockedDateMoment = moment(blockedDate);
                                      return (
                                        blockedDateMoment.isBetween(e.from, e.to, null, "[]") // Check if blocked date is within the selected range
                                      );
                                    });

                                    if (isRangeBlocked) {
                                      // Show a tooltip or warning message
                                      showNotification("danger", "Selected dates overlap with a blocked period.");
                                      return; // Prevent selection
                                    }

                                    // Update the form values
                                    setFieldValue("fromDate", moment(e.from).format("YYYY-MM-DD"));
                                    setFieldValue("toDate", moment(e.to).format("YYYY-MM-DD"));
                                    setRange(e);
                                    setCheckoutDetails("");
                                    setShowCalendar(false);
                                  } else if (e?.from) {
                                    // Handle single date selection (e.g., updating "From date")
                                    setFieldValue("fromDate", moment(e.from).format("YYYY-MM-DD"));
                                    setRange({ from: e.from, to: null }); // Clear "To date" if "From date" is updated
                                  }
                                }}
                                components={{
                                  DayContent: renderDay,
                                }}
                                disabled={disableDates}
                              />
                              {/* Old Version */}
                              {/* <DayPicker
                                month={month}
                                onMonthChange={(e) => {
                                  setMonth(e);
                                  AvailabilityDetails(moment(e).format("MM"));
                                }}
                                mode="range"
                                min={product?.minimum_rental_period}
                                max={product?.maximum_rental_period}
                                hidden={isPastDate}
                                selected={range}
                                onSelect={(e) => {
                                  setRange(e);
                                  if (e?.from) {
                                    setFieldValue(
                                      "fromDate",
                                      moment(e?.from).format("YYYY-MM-DD")
                                    );
                                  } else {
                                    setFieldValue("fromDate", "");
                                  }
                                  if (e?.to) {
                                    setFieldValue(
                                      "toDate",
                                      moment(e?.to).format("YYYY-MM-DD")
                                    );
                                  } else {
                                    setFieldValue("toDate", "");
                                  }

                                  setCheckoutDetails("");
                                  if (e?.from && e?.to) {
                                    setShowCalendar(false);
                                  }
                                }}
                                components={{
                                  DayContent: renderDay,
                                }}
                                disabled={disableDates}
                              /> */}

                            </Col>
                          )}
                        </Row>
                      </Form>
                      <Row>
                        <Col lg={12}>
                          <div className="noborder greytable p-3 mb-3 d-none">
                            <div className="d-flex align-items-center justify-content-between flex-wrap  mb-lg-4 mb-2">
                              <h6 className="font16 font700 colordark">
                                {product?.renter_delivery_carrier || 'Delivery to renter by UPS'}
                              </h6>
                              <h6 className="font16 font700 colordark">
                                Receive it before:{" "}
                                <span className="ms-1">{product?.booked_from}</span>
                              </h6>
                            </div>
                            <p className="font16 font400 colordark mb-0">
                              Tracking ID: #{product?.tracking_id ? product?.tracking_id : null}
                            </p>
                          </div>
                          <div className="noborder greytable p-3 mb-3 d-none">
                            <div className="d-flex align-items-center justify-content-between flex-wrap  mb-lg-4 mb-2">
                              <h6 className="font16 font700 colordark">
                                {product?.renter_delivery_carrier || 'Delivery to renter by UPS'}
                              </h6>
                              <h6 className="font16 font700 colordark">
                                Receive it before:{" "}
                                <span className="ms-1">{product?.booked_from}</span>
                              </h6>
                            </div>
                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                              <p className="font16 font400 colordark mb-0">
                                Tracking ID: #{product?.tracking_id ? product?.tracking_id : null}
                              </p>
                              <a
                                className="btn btn-theme my-lg-0 mt-3"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleShow();
                                }}
                              >
                                Print Shipping Label
                              </a>
                            </div>
                          </div>

                          {checkoutDetails && (
                            <table className="noborder greytable w-100 table">
                              <tbody>
                                <tr>
                                  <td>
                                    Sub Total (
                                    {checkoutDetails?.total_rental_days?.join(
                                      " "
                                    ) ?? "0 days"}
                                    )
                                  </td>
                                  <td className="text-end">
                                    {DEFAULT_CURRENCY} { }
                                    {checkoutDetails?.total_rental_price
                                      ? Number(
                                        checkoutDetails?.total_rental_price
                                      )?.toLocaleString("hi")
                                      : 0}
                                  </td>
                                </tr>
                                {checkoutDetails?.discount_days && (
                                  <tr>
                                    <td>
                                      {checkoutDetails?.discount_days} Discount ({checkoutDetails?.discount_applied})
                                    </td>
                                    <td className="text-end">
                                      {DEFAULT_CURRENCY} { }
                                      {((checkoutDetails?.total_rental_price ?? 0) - (checkoutDetails?.discounted_price ?? 0)).toLocaleString("hi")}
                                    </td>
                                  </tr>
                                )}

                                <tr>
                                  <td>Shaxify fee</td>
                                  <td className="text-end">
                                    {DEFAULT_CURRENCY}{" "}
                                    {checkoutDetails?.shaxify_fee
                                      ? Number(
                                        checkoutDetails?.shaxify_fee
                                      )?.toLocaleString("hi")
                                      : 0}
                                  </td>
                                </tr>
                                {/* Remove shipping fee */}
                                {/* <tr>
                                  <td>Estimated Shipping</td>
                                  <td className="text-end">
                                    {DEFAULT_CURRENCY}{" "}
                                    {checkoutDetails?.estimated_shipping
                                      ? Number(
                                        checkoutDetails?.estimated_shipping
                                      )?.toLocaleString("hi")
                                      : 0}
                                  </td>
                                </tr> */}
                                <tr>
                                  <td className="font700">Total</td>
                                  <td className="text-end font700">
                                    {DEFAULT_CURRENCY}{" "}
                                    {checkoutDetails?.total
                                      ? Number(
                                        checkoutDetails?.total
                                      )?.toLocaleString("hi")
                                      : 0}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          )}

                          <div className="d-flex align-items gap-3">
                            <button
                              className="btn btn-theme-outline mt-3 mb-1 w-50"
                              type="button"
                              onClick={() => {
                                if (checkLoggedInUser) {
                                  handleSubmit();
                                  setSelecetedOptions("rent");
                                } else {
                                  document.getElementById("headerlogin").click();
                                }
                              }}
                            >
                              Add to Cart
                            </button>
                            {checkoutDetails ? (
                              <button
                                className="btn btn-theme mt-3 mb-1 w-50"
                                type="button"
                                onClick={() => {
                                  if (checkLoggedInUser) {
                                    handleRentNow("rent");
                                  } else {
                                    document.getElementById("headerlogin").click();
                                  }
                                }}
                              >
                                Rent Now!
                              </button>
                            ) : (
                              <button
                                className="btn btn-theme mt-3 mb-1 w-50"
                                type="button"
                                onClick={() => {
                                  if (checkLoggedInUser) {
                                    setSelecetedOptions("price");
                                    handleSubmit();
                                  } else {
                                    document.getElementById("headerlogin").click();
                                  }
                                }}
                              >
                                Price Details
                              </button>
                            )}
                          </div>

                          <Row className="align-items-center mt-4 d-none">
                            <Col lg={8} sm={6} xs={6}>
                              <p className="font12 font700">
                                Due to insurance purposes we need you to take
                                pictures of the item 12h after receiving the item.
                              </p>
                            </Col>
                            <Col lg={4} sm={6} xs={6}>
                              <button
                                onClick={handleShowOne}
                                className="btn btn-theme w-100 mb-0"
                              >
                                Take pictures
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    
                    </div>
                </div>
              </Col>
            </Row>
            <div className="blue-box d-flex d-lg-none align-items-start gap-2 mt-3">
              <div>
                <StarsBlueIcon />
              </div>
              <div>
                <p className="font16 font700 colorblue">Squeaky Clean Policy</p>
                <p className="font16 font400 colorblue">
                  We ask all our lenders to clean all items before before being
                  sent.
                </p>
                <div className="text-end">
                  <a className="font14 font700">Learn More</a>
                </div>
              </div>
            </div>
            <div className="mt-3 d-lg-none">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  handleShowSupport();
                }}
                className="btn btn-default w-100"
              >
                <SupportIcon /> Support
              </Link>
            </div>
          </Container>
        </div>
      </div>
      <Footer />

      {/* Print slip modal */}

      <Modal
        show={false}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="text-end p-2">
          <a onClick={handleCloseTwo}>
            <CrossIcon />
          </a>
        </div>
        <Modal.Body>
          <div className="slip-img">
            <img src={require("../../assets/images/slip.png")} alt="img" />
          </div>
          <div className="text-center">
            <button className="btn btn-theme mt-3 mb-1 w-25">Print</button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Take picture modal */}
      <Modal
        show={showOne}
        onHide={handleCloseOne}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="picture-modal"
      >
        <div className="text-end p-2    ">
          <a onClick={handleCloseOne}>
            <CrossIcon />
          </a>
        </div>
        <Modal.Body>
          <Row>
            <Col lg={6} sm={12}>
              <div className="text-center mb-4">
                <h4 className="mb-2">Front Picture</h4>
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    ref={imageUploader}
                    style={{
                      display: "none",
                    }}
                  />
                  <div>
                    <img
                      ref={uploadedImage}
                      className="placeholder-img"
                      src={require("../../assets/images/placeholder-img.png")}
                      alt="img"
                      style={{
                        width: "205px",
                        height: "144px",
                        margin: "0 0 5px",
                      }}
                    />
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => imageUploader.current.click()}
                    >
                      <Button className="btn btn-theme-outline font700">
                        Take picture
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} sm={12}>
              <div className="text-center mb-4">
                <h4 className="mb-2">Back Picture</h4>
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    ref={imageUploader}
                    style={{
                      display: "none",
                    }}
                  />
                  <div>
                    <img
                      ref={uploadedImage}
                      className="placeholder-img"
                      src={require("../../assets/images/placeholder-img.png")}
                      alt="img"
                      style={{
                        width: "205px",
                        height: "144px",
                        margin: "0 0 5px",
                      }}
                    />
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => imageUploader.current.click()}
                    >
                      <Button className="btn btn-theme-outline font700">
                        Take picture
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} sm={12}>
              <div className="text-center mb-4">
                <h4 className="mb-2">Left Side</h4>
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    ref={imageUploader}
                    style={{
                      display: "none",
                    }}
                  />
                  <div>
                    <img
                      ref={uploadedImage}
                      className="placeholder-img"
                      src={require("../../assets/images/placeholder-img.png")}
                      alt="img"
                      style={{
                        width: "205px",
                        height: "144px",
                        margin: "0 0 5px",
                      }}
                    />
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => imageUploader.current.click()}
                    >
                      <Button className="btn btn-theme-outline font700">
                        Take picture
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} sm={12}>
              <div className="text-center mb-4">
                <h4 className="mb-2">Right Side</h4>
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    ref={imageUploader}
                    style={{
                      display: "none",
                    }}
                  />
                  <div>
                    <img
                      ref={uploadedImage}
                      className="placeholder-img"
                      src={require("../../assets/images/placeholder-img.png")}
                      alt="img"
                      style={{
                        width: "205px",
                        height: "144px",
                        margin: "0 0 5px",
                      }}
                    />
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => imageUploader.current.click()}
                    >
                      <Button className="btn btn-theme-outline font700">
                        Take picture
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <p className="font12 font700 color-dark">
            We need you to take pictures of the item status within the first 12
            hours after receiving the item. This is for insurance purposes and
            any claim of damages.
          </p>
        </Modal.Body>
      </Modal>

      {/* Contact Support */}
      <ContactSupport
        showSupportModal={showSupport}
        currentUser={userDetail}
        handleClose={handleClose}
      />
      {loading ? <Loader /> : null}
    </>
  );
};

export default RenterDetails;
