import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import * as Yup from "yup";
import { AddAddress } from "../../../Services/APIServices";
import showNotification from "../../../Services/NotificationService";
import { CrossIcon } from "../../../SvgIcons/allIcons";
import Loader from "../../../Commons/Loader/Loader";
const AddressModal = ({
  handleClosenewadd,
  locationList,
  handleShow,
  shownewadd,
}) => {
  const validationSchema = Yup.object({
    address1: Yup.string().required("This field is required").trim(),
    state: Yup.string().required("This field is required"),
    city: Yup.string().required("This field is required"),
    country: Yup.string().required("This field is required"),
    zipcode: Yup.string()
      .matches(/^[0-9]{5}/, "Enter a correct ZipCode it should be number")
      .max(10, "Maximum 10 digit required.")
      .required("This field is required")
      .min(6, "Minimum 6 digit required."),
  });
  const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Check if Google Maps API is loaded
    if (!window.google || !window.google.maps || !window.google.maps.places) {      
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        setTimeout(() => setIsGoogleLoaded(true), 1000); // Small delay to ensure API is available
      };
      document.head.appendChild(script);
    } else {
      setIsGoogleLoaded(true);
    }
  }, []);

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    handleBlur,
    touched,
    setFieldTouched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      address1: "",
      address2: "",
      state: "",
      city: "",
      country: "",
      zipcode: "",
      latitude: "",
      longitude: "",
    },
    validationSchema,

    onSubmit(values, { resetForm }) {
      let body = {
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        state: values.state,
        country: values.country,
        zipcode: values.zipcode,
        latitude: values.latitude ? values.latitude : 0,
        longitude: values.longitude ? values.longitude : 0,
      };
      let formData = new FormData();
      Object.keys(body).forEach((data) => {
        formData.append(data, body[data]);
      });
      setLoading(true);
      AddAddress(formData).then((resp) => {
        setLoading(false);
        if (resp?.status === 200) {
          handleClosenewadd();
          locationList();
          handleShow(resp?.data?.data);
          resetForm();
        } else {
          showNotification("danger", resp?.data?.message);
          handleClosenewadd();
          resetForm();
        }
      });
    },
  });

  const handlePlaces = (place) => {

    resetForm();

    place?.address_components?.map((item) => {
      if (item?.types?.includes("country")) {
        setFieldValue("country", item?.long_name);
      }
      if (item?.types?.includes("administrative_area_level_1")) {
        setFieldValue("state", item?.long_name);
      }
      if (item?.types?.includes("administrative_area_level_3")) {
        setFieldValue("city", item?.long_name);
      }
      if (item?.types?.includes("postal_code")) {
        setFieldValue("zipcode", item?.long_name);
      }
    });
    setFieldValue("address1", place?.formatted_address);
    setFieldValue("latitude", place?.geometry?.location?.lat());
    setFieldValue("longitude", place?.geometry?.location?.lng());
  };

  return (
    <Modal
      show={shownewadd}
      onHide={() => {
        handleClosenewadd();
        resetForm();
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="delete-items"
    >
      <div className="middle-area text-start new-address">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h4 className="text-start">Add new address</h4>
          <a onClick={handleClosenewadd}>
            <CrossIcon />
          </a>
        </div>
        <Modal.Body className="px-0 pb-0">
          <Row>
            <Col sm={12} className="mx-auto">
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label className="font700 font12 secondaycolor">
                    Address 1 <span className="error">*</span>
                  </Form.Label>
                  {isGoogleLoaded ? (
                    <Autocomplete
                      apiKey={process.env.REACT_APP_MAP_KEY}
                      placeholder="Address 1"
                      className="form-control"
                      name="address1"
                      value={values?.address1}
                      options={{
                        types: ["address"],
                        componentRestrictions: { country: "in" },
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onPlaceSelected={(place) => {
                        handlePlaces(place);
                      }}
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      placeholder="Loading Google Maps..."
                      disabled
                    />
                  )}
                  <span className="text-start required">
                    {touched.address1 && errors.address1}{" "}
                  </span>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="font700 font12 secondaycolor">
                    Address 2
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="address2"
                    placeholder="Address 2"
                    onChange={handleChange}
                    value={values?.address2}
                    onBlur={handleBlur}
                  />
                </Form.Group>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className="font700 font12 secondaycolor">
                        City <span className="error">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="city"
                        placeholder="City"
                        onChange={handleChange}
                        value={values?.city}
                        onBlur={handleBlur}
                      />
                      <span className="text-start required">
                        {touched.city && errors.city}
                      </span>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className="font700 font12 secondaycolor">
                        State <span className="error">*</span>
                      </Form.Label>

                      <Form.Control
                        type="text"
                        placeholder="State"
                        name="state"
                        value={values?.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <span className="text-start required">
                        {touched.state && errors.state}{" "}
                      </span>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className="font700 font12 secondaycolor">
                        Country <span className="error">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Country"
                        name="country"
                        value={values?.country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-start required">
                        {touched.country && errors.country}{" "}
                      </span>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className="font700 font12 secondaycolor">
                        Zip Code<span className="error">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="zipcode"
                        maxLength="10"
                        placeholder="Enter zipcode"
                        onChange={handleChange}
                        value={values?.zipcode}
                        onBlur={handleBlur}
                      />
                      <span className="text-start required">
                        {touched.zipcode && errors.zipcode}
                      </span>
                    </Form.Group>
                  </Col>
                </Row>

                <Button
                  className="btn btn-theme font700 mb-0 w-100"
                  onClick={handleSubmit}
                >
                  Add
                </Button>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </div>
      {loading && <Loader />}
    </Modal>
  );
};

export default AddressModal;
